import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { useQuery } from "@vue/apollo-composable";
import { ResultsOpexPropertyDocument, ResultsOpexPortfolioDocument, AssetManagementCategoryType, MetricPeriodType } from "~/graphql/generated/graphql";
import { useAreaQuery } from "./useAreaQuery";
export var useOpexQuery = function useOpexQuery(variables) {
  var namespace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "preferencesNamespacePortfolioPropertyOpex";
  var preferences = usePreferencesNamespace(namespace);
  var selectedDateRange = useOpexSelectedDateRange(computed(function () {
    return variables.value.propertyId;
  }));
  var dateRange = computed(function () {
    var _variables$value$date, _variables$value$date2, _selectedDateRange$re, _variables$value$date3, _variables$value$date4, _selectedDateRange$re2;
    return {
      from: (_variables$value$date = (_variables$value$date2 = variables.value.dateRange) === null || _variables$value$date2 === void 0 ? void 0 : _variables$value$date2.from) !== null && _variables$value$date !== void 0 ? _variables$value$date : (_selectedDateRange$re = selectedDateRange.result.value) === null || _selectedDateRange$re === void 0 ? void 0 : _selectedDateRange$re.from,
      to: (_variables$value$date3 = (_variables$value$date4 = variables.value.dateRange) === null || _variables$value$date4 === void 0 ? void 0 : _variables$value$date4.to) !== null && _variables$value$date3 !== void 0 ? _variables$value$date3 : (_selectedDateRange$re2 = selectedDateRange.result.value) === null || _selectedDateRange$re2 === void 0 ? void 0 : _selectedDateRange$re2.to
    };
  });
  var periodType = computed(function () {
    return preferences.result.value && "periodType" in preferences.result.value ? preferences.result.value.periodType : MetricPeriodType.Yearly;
  });
  var queryVariables = function queryVariables() {
    return {
      input: {
        categoryType: AssetManagementCategoryType.Opex,
        from: dateRange.value.from,
        to: dateRange.value.to,
        periodType: periodType.value,
        propertyId: variables.value.propertyId,
        portfolioId: variables.value.portfolioId
      }
    };
  };
  var propertyQuery = useQuery(ResultsOpexPropertyDocument, queryVariables, function () {
    return {
      enabled: !!preferences.result.value && !!dateRange.value.from && !!dateRange.value.to && !!variables.value.propertyId
    };
  });
  var portfolioQuery = useQuery(ResultsOpexPortfolioDocument, queryVariables, function () {
    return {
      enabled: !!preferences.result.value && !!dateRange.value.from && !!dateRange.value.to && !!variables.value.portfolioId
    };
  });
  var query = computed(function () {
    return variables.value.propertyId ? propertyQuery : variables.value.portfolioId ? portfolioQuery : null;
  });
  var areaQuery = useAreaQuery(variables);
  var cells = computed(function () {
    var _query$value, _query$value$result$v, _query$value2, _query$value2$result$;
    return (_query$value = query.value) !== null && _query$value !== void 0 && (_query$value$result$v = _query$value.result.value) !== null && _query$value$result$v !== void 0 && _query$value$result$v.categoryCells ? _toConsumableArray((_query$value2 = query.value) === null || _query$value2 === void 0 ? void 0 : (_query$value2$result$ = _query$value2.result.value) === null || _query$value2$result$ === void 0 ? void 0 : _query$value2$result$.categoryCells).sort(function (a, b) {
      return a.order - b.order;
    }) : undefined;
  });
  var result = computed(function () {
    return areaQuery.result.value && cells.value ? {
      cells: cells.value,
      entityData: areaQuery.result.value
    } : undefined;
  });
  var loading = computed(function () {
    var _query$value3;
    return areaQuery.loading.value || ((_query$value3 = query.value) === null || _query$value3 === void 0 ? void 0 : _query$value3.loading.value) || preferences.loading.value || !variables.value.dateRange && selectedDateRange.loading.value;
  });
  return {
    result: result,
    loading: loading
  };
};